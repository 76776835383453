<template>
  <div class="App">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<!-- <style lang="scss" src="./App.scss" scoped></style> -->
