<template>
  <div class="Home flex items-center justify-center h-screen">
    <div class="p-4 text-center">
      <img class="mx-auto" src="/apple-touch-icon.png" alt="" />
      <h1 class="mt-6 font-bold text-3xl">MazeMaker.app</h1>
      <router-link
        :to="{ name: 'Maze' }"
        class="inline-block mt-2 w-full sm:w-auto whitespace-nowrap bg-red-500 outline-none focus:ring ring-red-300 focus:outline-none text-white font-bold uppercase px-3 py-1"
      >
        Make Your Maze
      </router-link>
      <p class="mt-7 text-gray-600">
        A fun <strong>maze generator</strong> made by
        <a
          href="https://truefrontierapps.com"
          class="border-b text-red-600 border-red-600 hover:text-blue-500 hover:border-blue-500"
        >
          <b>TRUE FRONTIER</b>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { clone } from '@/utils/object';

export default {
  name: 'Home',
};
</script>

<!-- <style lang="scss" src="./Home.scss" scoped></style> -->
