import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/maze',
    name: 'Maze',
    component: () => import(/* webpackChunkName: "maze" */ '../views/Maze.vue'),
  },
  {
    path: '/maze2',
    name: 'Maze2',
    component: () => import(/* webpackChunkName: "maze2" */ '../views/Maze2.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
